@font-face {
  font-family: 'SD';
  src: url('fonts/SD.eot?k1k4wf');
  src:
    url('fonts/SD.eot?k1k4wf#iefix') format('embedded-opentype'),
    url('fonts/SD.ttf?k1k4wf') format('truetype'),
    url('fonts/SD.woff?k1k4wf') format('woff'),
    url('fonts/SD.svg?k1k4wf#SD') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ico-'],
[class*=' ico-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'SD' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-address-book:before {
  content: '\e900';
}
.ico-arrow-down:before {
  content: '\e901';
}
.ico-arrow-left:before {
  content: '\e902';
}
.ico-arrow-right:before {
  content: '\e903';
}
.ico-arrow-up:before {
  content: '\e904';
}
.ico-bag:before {
  content: '\e905';
}
.ico-basket:before {
  content: '\e906';
}
.ico-card:before {
  content: '\e907';
}
.ico-caret-down:before {
  content: '\e908';
}
.ico-caret-left:before {
  content: '\e909';
}
.ico-caret-right:before {
  content: '\e90a';
}
.ico-caret-up:before {
  content: '\e90b';
}
.ico-check:before {
  content: '\e90c';
}
.ico-cross:before {
  content: '\e90d';
}
.ico-cross-full:before {
  content: '\e90e';
}
.ico-d-building:before {
  content: '\e90f';
}
.ico-d-buildings:before {
  content: '\e910';
}
.ico-d-calculator:before {
  content: '\e911';
}
.ico-d-factory:before {
  content: '\e913';
}
.ico-d-files-empty:before {
  content: '\e914';
}
.ico-d-files-full:before {
  content: '\e915';
}
.ico-d-grange:before {
  content: '\e916';
}
.ico-d-house:before {
  content: '\e917';
}
.ico-d-house-arrow:before {
  content: '\e918';
}
.ico-d-house-lightning:before {
  content: '\e919';
}
.ico-d-house-plus:before {
  content: '\e91a';
}
.ico-d-house-star:before {
  content: '\e91b';
}
.ico-d-house-star-2:before {
  content: '\e91c';
}
.ico-d-hut:before {
  content: '\e91d';
}
.ico-d-letter:before {
  content: '\e91e';
}
.ico-d-magic-wand:before {
  content: '\e91f';
}
.ico-d-pen-2:before {
  content: '\e920';
}
.ico-d-pen-empty:before {
  content: '\e921';
}
.ico-d-pen-full:before {
  content: '\e922';
}
.ico-d-pencil:before {
  content: '\e923';
}
.ico-d-ping:before {
  content: '\e924';
}
.ico-d-ping-2:before {
  content: '\e925';
}
.ico-d-plus:before {
  content: '\e926';
}
.ico-d-silhouette:before {
  content: '\e927';
}
.ico-destruction:before {
  content: '\e928';
}
.ico-dots:before {
  content: '\e929';
}
.ico-download:before {
  content: '\e912';
}
.ico-edit:before {
  content: '\e92a';
}
.ico-error:before {
  content: '\e92b';
}
.ico-euro-heart:before {
  content: '\e92c';
}
.ico-facebook:before {
  content: '\e948';
}
.ico-home-micro:before {
  content: '\e92d';
}
.ico-home-sas:before {
  content: '\e92e';
}
.ico-home-sasu:before {
  content: '\e92f';
}
.ico-info:before {
  content: '\e930';
}
.ico-instagram:before {
  content: '\e94d';
}
.ico-letter:before {
  content: '\e931';
}
.ico-letter-open:before {
  content: '\e949';
}
.ico-life-ring:before {
  content: '\e932';
}
.ico-lightbulb:before {
  content: '\e94a';
}
.ico-lightning:before {
  content: '\e933';
}
.ico-linkedin:before {
  content: '\e94e';
}
.ico-lock:before {
  content: '\e934';
}
.ico-mail-send:before {
  content: '\e935';
}
.ico-menu-burger:before {
  content: '\e936';
}
.ico-minus:before {
  content: '\e94b';
}
.ico-package:before {
  content: '\e94c';
}
.ico-phone:before {
  content: '\e937';
}
.ico-ping:before {
  content: '\e938';
}
.ico-plus:before {
  content: '\e939';
}
.ico-plus-round:before {
  content: '\e93a';
}
.ico-satchel:before {
  content: '\e93b';
}
.ico-sd-picto:before {
  content: '\e93c';
}
.ico-search:before {
  content: '\e93d';
}
.ico-search-full:before {
  content: '\e93e';
}
.ico-setting:before {
  content: '\e93f';
}
.ico-shield:before {
  content: '\e940';
}
.ico-silhouette-plus:before {
  content: '\e941';
}
.ico-silhouettes:before {
  content: '\e942';
}
.ico-star-full:before {
  content: '\e943';
}
.ico-star-round:before {
  content: '\e944';
}
.ico-tiktok:before {
  content: '\e94f';
}
.ico-trash:before {
  content: '\e945';
}
.ico-unlock:before {
  content: '\e946';
}
.ico-warning:before {
  content: '\e947';
}
.ico-whatsapp:before {
  content: '\e950';
}
.ico-x:before {
  content: '\e951';
}
.ico-youtube:before {
  content: '\e952';
}
